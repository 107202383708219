import Navigation from "../components/Navigation";
import teamIcon from "../images/team_hami.png"

const Home = () => {
  return <>
    <Navigation />
    <div className="flex justify-center">
      <div>
        <img src={teamIcon} className="h-80 my-6" alt="team hami"></img>
        <div className="flex justify-center text-xl">We make games!</div>
      </div>
    </div>
  </>
}

export default Home;
