import Navigation from "../components/Navigation";
import ruthImg from "../images/ruth.jpg"
import simonImg from "../images/simon.jpg"
import fengfengImg from "../images/fengfeng.jpg"

const Team = () => {
  return <>
    <Navigation />
    <div className="flex justify-center text-2xl my-10">We want to make games that are worth loving!</div>
    <div className="flex justify-center text-xl mb-7">Our Team</div>
    <div className="flex justify-center space-x-10">
      <div>
        <img src={simonImg} alt="simon" className="rounded-full mb-4 size-40" />
        <div className="flex justify-center">
          <div>
            <div className="text-xl font-semibold">Simon Chen</div>
            <div>Game Design</div>
            <div>Programming</div>
          </div>
        </div>
      </div>
      <div>
        <img src={ruthImg} alt="ruth" className="rounded-full mb-4 size-40" />
        <div className="flex justify-center">
          <div>
            <div className="text-xl font-semibold">Ruth Wang</div>
            <div>Game Design</div>
            <div>Art</div>
          </div>
        </div>
      </div>
      <div>
        <img src={fengfengImg} alt="fengfeng" className="rounded-full mb-4 size-40" />
        <div className="flex justify-center">
          <div>
            <div className="text-xl font-semibold">Ye Feng</div>
            <div>3D Modelling</div>
            <div>Chinese Translation</div>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default Team;
