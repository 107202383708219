import { createRoot } from "react-dom/client";
import Home from './pages/Home';
import About from './pages/About'
import Games from './pages/Games'

import Decay from "./pages/Decay";
import './index.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/games/",
    element: <Games />,
  },
  {
    path: "/about/",
    element: <About />,
  },
  {
    path: "/games/decay/",
    element: <Decay />,
  },
]);

createRoot(document.getElementById("root")).render(
  <>
    <RouterProvider router={router} />
  </>
);