import logo from '../images/team_hami_melon.png';

import { Link } from "react-router-dom";


const Navigation = () => {
  return (
    <header className="bg-slate-100 h-15 flex justify-between items-center py-2 px-4">
        <Link to="/">
          <img src={logo} className="h-12" alt="logo" />
        </Link>
        <div className="flex space-x-3">
          <Link to="/" className="hover:font-semibold">home</Link>
          <Link to="/games" className="hover:font-semibold">games</Link>
          <Link to="/about" className="hover:font-semibold">about</Link>
        </div>
    </header>
  );
}

export default Navigation;