import Navigation from "../components/Navigation";
import decayLogo from "../images/decay_title.png"

import { Link } from "react-router-dom";

const Games = () => {
  return <>
    <Navigation />
    <div className="flex justify-center my-10">
      <div className="text-5xl font-light font-number mr-5">2024</div>
      <a href="/games/" className="bg-slate-400 h-40 w-2/3 flex justify-center p-4 hover:bg-slate-500">
        <div className="text-white content-center text-4xl">To Be Announced</div>
      </a>
    </div>
    <div className="flex justify-center my-10">
      <div className="ml-28 font-semibold text-xl">
        Non-Commercial Games
      </div>
    </div>
    <div className="flex justify-center my-10">
      <div className="text-5xl font-light font-number mr-5">2021</div>
      <Link to="/games/decay" className="bg-black h-40 w-2/3 flex justify-center p-4 hover:bg-red-900">
        <img src={decayLogo} alt="decay logo"></img>
      </Link>
    </div>
  </>
}

export default Games;
