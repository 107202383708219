import Navigation from "../components/Navigation";
import decayLogo from "../images/decay_title.png"

const Decay = () => {
  return <>
    <Navigation />
    <div className="bg-black w-auto text-white flex justify-center">Our first game</div>
    <div className="flex justify-center my-10">
      <a href="https://gamejolt.com/games/decay/571196" className="bg-black h-40 w-2/3 flex justify-center p-4 hover:bg-red-900">
        <img src={decayLogo} alt="decay logo"></img>
      </a>
    </div>
  </>
}

export default Decay;
